const Menu = [
  {
    heading: 'menu',
    route: '/trainer',
    pages: [
      {
        heading: 'Add Trainer',
        route: '/trainer/add-trainer',
        fontIcon: 'fa-user',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
      },
      {
        heading: 'Trainer List',
        route: '/trainer/trainer-list',
        fontIcon: 'fa-user',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
      },
      {
        heading: 'Trainer Tracking',
        route: '/trainer/trainer-search',
        fontIcon: 'fa-user',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
      },

      {
        heading: 'Trainer Search',
        route: '/trainer/trainer-report',
        fontIcon: 'fa-user',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
      },
    ],
  },
];

export default Menu;
